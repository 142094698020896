<template>
  <span>
    <v-overlay :value="isLoading" z-index="500">
      <div class="text-center pa-8 rounded-lg" style="width: 200px;">
         <v-progress-circular indeterminate color="white"></v-progress-circular>
      </div>
    </v-overlay>
    <v-dialog
      v-model="isOpen"
      persistent
      max-width="400"
    >
    <v-card>
      <v-card-title class="text-h5">
        <span>
          You sure you want to <span class="text-decoration-underline red--text"> Delete</span>
        </span>
        <span>
          <v-avatar size="42">
            <v-img contain :src="course.organization.logoUrl"></v-img>
          </v-avatar>
          <span class="ml-2">
            {{ course.name }} ?
          </span>
        </span>
      </v-card-title>
      <v-card-text>Although deleting a course should be self-explanatory, let's provide some clarification. By deleting this course, all information related to it will be removed from Sea2Sea Scuba. It is important to understand that this action will result in the complete removal of ALL information associated with the course. Therefore, please ensure that you truly want to proceed before taking this step.</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="doClose"
        >
          Cancel
        </v-btn>
        <v-btn
          color="red"
          text
          @click="doDestroy"
        >
          Destroy
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </span>
</template>

<script>
export default {
  name: 'deleteCourse',
  data: () => ({
    isLoading: false,
    isOpen: false,
    course: {
      id: 0,
      name: String(),
      organization: String()
    }
  }),
  methods: {
    doOpen (id) {
      this.course.id = id
      this.isLoading = true

      this.$services.course.doRead(id).then(course => {
        this.course.name = course.name
        this.course.organization = course.organization

        this.isOpen = true
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doDestroy () {
      this.isOpen = false

      this.isLoading = true
      this.$services.course.doDestroy(this.course.id).then().catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.$emit('didDestroy')
        this.isLoading = false
      })
    },
    doClose () {
      this.isOpen = false
    }
  }
}
</script>

<style scoped>

</style>
