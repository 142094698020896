<template>
  <section>
    <input type="file" ref="imageInput" style="display: none" @change="doUploadImage"/>
    <v-btn @click="doSelectImage" color="primary" :class="errorBox ? 'errorBox' : ''" block>
      <div v-if="upload.isUploading">
        <span class="mr-2">Uploading</span>
        <v-progress-circular :rotate="-90" :value="upload.progress" color="grey lighten-5" size="20"></v-progress-circular>
      </div>
      <div v-else-if="imageUrl !== null && isOpen === false">
        <span class="mr-2">Image uploaded</span>
        <font-awesome-icon icon="fa-solid fa-check" class="ml-2"/>
      </div>
      <div v-else>
        <span class="mr-2">Upload image</span>
        <font-awesome-icon size="xl" :icon="['fas', 'image']" fixed-width></font-awesome-icon>
      </div>
    </v-btn>
  </section>
</template>

<script>
export default {
  name: 'ImageUploadAndCrop',
  props: {
    errorBox: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isOpen: false,
    imageUrl: null,
    coordinates: null,
    cropperReady: false,
    upload: {
      isUploading: false,
      progress: 0
    }
  }),
  methods: {
    onCropChange ({ coordinates }) {
      this.coordinates = coordinates
    },
    onImageReady () {
      this.cropperReady = true
    },
    doSelectImage () {
      this.$refs.imageInput.click()
    },
    doSave () {
      this.$emit('select-file', {
        src: this.imageUrl,
        alt: ''
      })
      this.doClose()
    },
    doUploadImage (event) {
      const files = event.target.files
      if (files.length >= 1) {
        const file = files[0]

        const fileExtension = file.name.split('.').pop()
        const fileName = `${this.$uuid.v4()}.${fileExtension}`

        this.upload.isUploading = true
        this.cropperReady = false

        this.$services.media.doUploadImage('imagery', fileName, file, progress => {
          this.upload.progress = Math.ceil(progress.loaded / progress.total * 100)
        }).then(uploadedImage => {
          this.imageUrl = uploadedImage.Location
          this.doSave()
        }).catch(error => {
          this.$root.$emit('showError', error.message)
        }).finally(_ => {
          this.upload.isUploading = false
          this.upload.progress = 0
        })
      }
    },
    doInit (currentImage = null) {
      if (currentImage === null) {
        this.imageUrl = null
        this.coordinates = null
      } else {
        if ('url' in currentImage && 'coordinates' in currentImage) {
          this.imageUrl = currentImage.url
          this.coordinates = currentImage.coordinates
        }
      }

      this.cropperReady = false
    }
  }
}
</script>

<style scoped>
::v-deep .background {
  background: white;
}

.centerIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  margin: auto;
}

.errorBox {
  border: 1px solid red !important;
}
</style>
