<template>
  <section>
    <v-card flat outlined style="height: 202px;">
      <input type="file" ref="imageInput" style="display: none" @change="doUploadImage"/>
      <v-card-text style="height: 150px;" v-if="!upload.isUploading">
        <v-window v-model="current">
          <v-window-item v-for="(image, index) in images" :key="index">
            <div style="height: 100%">
              <v-img :src="image.url" height="150" aspect-ratio="1.7">
                <div>
                  <v-btn style="width: 40px; height: 60px;" elevation="2" @click="doSelectImage(index)" :disabled="upload.isUploading" class="centerIcon rounded-circle">
                    <font-awesome-icon size="xl" :icon="['fas', 'image']" fixed-width></font-awesome-icon>
                  </v-btn>
                  <v-btn v-if="images.length >= 2" small color="error" style="float:right;" @click="doRemoveImage(index)" class="ma-1">
                    <font-awesome-icon size="lg" :icon="['fas', 'trash']" fixed-width></font-awesome-icon>
                  </v-btn>
                </div>
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center" v-if="image.url != null">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
          </v-window-item>
        </v-window>
      </v-card-text>
      <v-card-text v-else style="height: 150px;">
        Uploading image...
        <v-progress-circular :rotate="-90" :value="upload.progress" color="primary" class="centerIcon"></v-progress-circular>
      </v-card-text>
      <v-card-actions v-if="images[0].url != null">
        <span v-if="images.length >= 2">
          <v-btn color="primary" variant="flat" @click="doGoBack">
            <font-awesome-icon size="lg" :icon="['fas', 'arrow-left']" fixed-width></font-awesome-icon>
          </v-btn>
          <v-btn color="primary" variant="flat" @click="doGoNext" class="ml-2">
            <font-awesome-icon size="lg" :icon="['fas', 'arrow-right']" fixed-width></font-awesome-icon>
          </v-btn>
        </span>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="flat" @click="doAddMoreImages">
          <font-awesome-icon size="lg" :icon="['fas', 'square-plus']" fixed-width class="mr-2"></font-awesome-icon>
          Image
        </v-btn>
      </v-card-actions>
    </v-card>
  </section>
</template>

<script>
export default {
  name: 'ImageUploadAndShuffle',
  data: () => ({
    current: 0,
    upload: {
      currentIndex: 0,
      isUploading: false,
      progress: 0
    },
    images: [
      {
        url: null
      }
    ]
  }),
  methods: {
    doGoBack () {
      this.current = (this.current > 0 ? this.current - 1 : 0)
    },
    doGoNext () {
      this.current = (this.current >= (this.images.length - 1) ? (this.images.length - 1) : (this.current + 1))
    },
    doAddMoreImages () {
      this.images.push({
        url: null
      })

      this.current = (this.images.length - 1)
    },
    doRemoveImage (index) {
      this.images.splice(index, 1)
      this.$emit('change')
    },
    doSelectImage (index) {
      this.upload.currentIndex = index
      this.$refs.imageInput.click()
    },
    doClearImageInput () {
      this.$refs.imageInput.value = null
    },
    doUploadImage (event) {
      const files = event.target.files
      if (files.length >= 1) {
        const file = files[0]

        const fileExtension = file.name.split('.').pop()
        const fileName = `${this.$uuid.v4()}.${fileExtension}`

        this.upload.isUploading = true

        this.$services.media.doUploadImage('imagery', fileName, file, progress => {
          this.upload.progress = Math.ceil(progress.loaded / progress.total * 100)
        }).then(uploadedImage => {
          try {
            this.images[this.upload.currentIndex].url = uploadedImage.Location
            this.$emit('change')
          } catch (error) {
            this.$root.$emit('showWarning', 'Model error, self fixing. Please report issue')
            this.images = [{
              url: uploadedImage.Location
            }]
          }
        }).catch(error => {
          this.$root.$emit('showError', error.message)
        }).finally(_ => {
          this.upload.isUploading = false
          this.upload.progress = 0
          this.doClearImageInput()
        })
      }
    },
    doDefineImages (images) {
      this.doInit()
      this.$nextTick(() => {
        this.images = images
      })
    },
    doInit () {
      this.current = 0
      this.upload = {
        currentIndex: 0,
        isUploading: false,
        progress: 0
      }
      this.images = [
        {
          url: null
        }
      ]
    }
  },
  mounted () {
    this.doInit()
  }
}
</script>

<style scoped>
.centerIcon {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
</style>
