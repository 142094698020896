<template>
  <span>
    <v-overlay :value="isLoading" absolute opacity="0.7" z-index="1000">
      <v-progress-circular
        indeterminate
        color="accent"
        :size="50"
        :width="5"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="isOpen" persistent max-width="1200px">
      <v-card class="ma-0 overflow-y-hidden">
        <v-card-title>
          <span class="text-h5">Course Information</span>
        </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="doCreate" fast-fail ref="form">
              <v-container fluid>
                <v-row>
                  <v-col>
                    <v-select
                      outlined
                      :items="organizations"
                      v-model="course.organizationId"
                      item-text="name"
                      item-value="id"
                      label="Organization"
                      :rules="inputs.rules.simplyRequired"
                    >
                      <template v-slot:item="{ item }">
                        <v-avatar size="42">
                          <v-img contain :src="item.logoUrl"></v-img>
                        </v-avatar>
                        <span class="ml-2">
                          {{ item.name }}
                        </span>
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-avatar size="42">
                          <v-img contain :src="item.logoUrl"></v-img>
                        </v-avatar>
                        <span class="ml-2">
                          {{ item.name }}
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col>
                    <v-text-field outlined label="Name" v-model="course.name" hint="Official Course Name" :rules="inputs.rules.simplyRequired"></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <v-textarea outlined v-model="course.subtitle" label="Subtitle" hint="Short description of the Course" no-resize rows="2" :rules="inputs.rules.simplyRequired" :counter="250"></v-textarea>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <v-btn @click="doOpenWysiwygEditor()" :class="!(isValidated !== null && !didOpenEditor) ? 'rounded' : 'customError rounded'" depressed outlined tile large block plain>Content and Description</v-btn>
                    <span class="caption ml-1 red--text" v-if="(isValidated !== null && !didOpenEditor)">Don't forget to view course content and description before submitting</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-checkbox v-model="areImagesSetDuringValidation" off-icon="fa-regular fa-square" :rules="inputs.rules.simplyRequired" style="display: none;" :disabled="true"></v-checkbox>
                    <image-upload-and-shuffle ref="images" :class="areImagesSetDuringValidation ? 'rounded' : 'customError rounded'" @change="doLoadImages"></image-upload-and-shuffle>
                    <span class="caption red--text ml-2" v-if="!areImagesSetDuringValidation">At least one image is required</span>
                  </v-col>
                  <v-col>
                    <v-text-field prepend-inner-icon="fa-dollar-sign" label="Cost" v-model.number="costAmount" outlined hint="Purchase price for the course" :rules="inputs.rules.simplyRequiredNumbers"></v-text-field>
<!--                    <v-card>-->
<!--                      <v-card-text>-->
<!--                        <span>Cost breakdown information</span>-->
<!--                        <ul>-->
<!--                          <li class="caption">Course cost: <span class="font-weight-bold">$ {{ costAmount.toFixed(2) }}</span></li>-->
<!--                          <li class="caption">Sales Tax (if applicable): <span class="font-weight-bold">9% ( $ {{ (costAmount * 0.09).toFixed(2) }} )</span></li>-->
<!--                          <li class="caption">Total checkout price for one: <span class="font-weight-bold">$ {{ (costAmount * 1.09).toFixed(2) }}</span></li>-->
<!--                        </ul>-->
<!--                      </v-card-text>-->
<!--                    </v-card>-->
                  </v-col>
                 </v-row>
                 <v-row>
                  <v-col>
                    <v-btn @click="doClose">Cancel</v-btn>
                  </v-col>
                  <v-col align="right">
                    <v-btn color="success" type="submit">Add Course</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
      </v-card>
    </v-dialog>
    <wysiwyg-editor ref="editor" @Save="doSaveCourseContent"></wysiwyg-editor>
  </span>
</template>

<script>
import WysiwygEditor from '@/components/tools/wysiwygEditor.vue'
import ImageUploadAndShuffle from '@/components/image/ImageUploadAndShuffle.vue'

export default {
  name: 'addCourse',
  components: { ImageUploadAndShuffle, WysiwygEditor },
  data: () => ({
    isLoading: false,
    isOpen: false,
    organizations: [],
    isValidated: null,
    didOpenEditor: false,
    course: {
      id: 0,
      name: String(),
      subtitle: String(),
      content: String(),
      organizationId: Number(),
      images: [],
      cents: Number()
    }
  }),
  computed: {
    costAmount: {
      get: function () {
        return (this.course.cents / 100)
      },
      set: function (newValue) {
        this.course.cents = newValue * 100
      }
    },
    areImagesSetDuringValidation () {
      if (this.isValidated !== null) {
        return (this.course.images.length >= 1)
      } else {
        return true
      }
    }
  },
  methods: {
    doOpen () {
      this.doInit()

      this.isLoading = true

      this.$services.course.organization.doReadAll().then(organizations => {
        this.organizations = organizations
        this.isOpen = true
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false

        this.$refs.images.doInit()
        this.$refs.editor.doInit()
        this.$refs.form.resetValidation()
      })
    },
    doClose () {
      this.$refs.form.resetValidation()
      this.isOpen = false
      this.$emit('doLoad')
    },
    doLoadImages () {
      this.course.images = this.$refs.images.images.filter(image => image.url != null)
    },
    doCreate () {
      this.doLoadImages()
      this.isValidated = this.$refs.form.validate()

      if (this.isValidated && (this.course.images.length >= 1) && this.didOpenEditor) {
        this.isLoading = true

        this.$services.course.doCreate(this.course.name, this.course.subtitle, this.course.content, this.course.organizationId, this.course.images, this.course.cents, this.course.queueMinimum).then(_ => {
          this.doClose()
        }).catch(error => {
          this.$root.$emit('showError', error.message)
        }).finally(_ => {
          this.isLoading = false
        })
      }
    },
    doOpenWysiwygEditor () {
      this.didOpenEditor = true
      this.$refs.editor.doOpen('Content and Description', this.course.content)
    },
    doSaveCourseContent (content) {
      this.course.content = content
    },
    doInit () {
      this.isValidated = null
      this.didOpenEditor = false
      this.course = {
        id: 0,
        name: String(),
        subtitle: String(),
        content: '<h2>This is an example of the course content!</h2><hr contenteditable="false"><p>What you see here is what you get! (WYSIWYG editor)</p><p>Add any information you would like, edit this and set what ever you want as fit!</p><p>When a customer clicks on a course, this is the main content they will see. Sometimes more words isn\'t better!</p><ul><li><p>Be <strong>concise</strong> and use <strong>keywords</strong>!</p></li><li><p><u>Check your spelling!</u></p></li><li><p>You can add <a href="https://sea2seascuba.com" rel="noopener noreferrer nofollow">links</a></p></li><li><p>You can add <u>block quotes</u></p></li></ul><blockquote><p>"The best course for finding your true self!"</p></blockquote><p>This content is <s>not</s> up to date!<br><br class="ProseMirror-trailingBreak"></p>',
        organizationId: Number(),
        images: [],
        cents: Number()
      }
    }
  },
  mounted () {

  }
}
</script>

<style scoped>
.customError {
  border: 2px red solid;
  color: red;
}
</style>
