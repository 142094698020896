import { render, staticRenderFns } from "./deleteCourse.vue?vue&type=template&id=fd251122&scoped=true&"
import script from "./deleteCourse.vue?vue&type=script&lang=js&"
export * from "./deleteCourse.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd251122",
  null
  
)

export default component.exports