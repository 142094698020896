<template>
  <section>
    <v-dialog v-model="isOpen" persistent fullscreen transition="dialog-top-transition">
      <v-card class="ma-0 overflow-y-hidden lighten-5">
        <v-toolbar dark color="primary">
          <v-btn color="error" @click="doClose">
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <span class="title">{{ this.title }}</span>
          <v-spacer></v-spacer>
          <v-btn dark @click="doSave">
            Save
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-0">
         <tiptap-vuetify class="wysiwyg mt-0" v-model="content" :extensions="editor.extensions" :native-extensions="editor.nativeExtensions" :toolbar-attributes="{ color: 'primary' }"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { TiptapVuetify, Heading, Image, Bold, Italic, Strike, Underline, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import wysiwygImageUpload from '@/components/image/wysiwygImageUpload.vue'
// import Youtube from '@tiptap/extension-youtube'
export default {
  name: 'wysiwygEditor',
  components: { TiptapVuetify },
  data: () => ({
    isOpen: false,
    title: String(),
    content: String(),
    editor: {
      extensions: [],
      nativeExtensions: []
    }
  }),
  methods: {
    doOpen (title, content) {
      this.title = title
      this.content = content
      this.isOpen = true
      this.didOpen = this.isOpen
    },
    doSave () {
      this.$emit('Save', this.content)
      this.doClose()
    },
    doClose () {
      this.isOpen = false
      this.$nextTick(() => {
        this.title = String()
        this.content = String()
      })
    },
    doInit () {
      this.title = String()
      this.content = String()
      this.isOpen = false
    }
  },
  created () {
    this.editor.extensions = [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      HorizontalRule,
      Paragraph,
      HardBreak,
      [Image, {
        options: {
          imageSourcesOverride: true,
          imageSources: [
            { component: wysiwygImageUpload, name: 'Image' }
          ]
        }
      }]
    ]
  }
}
</script>

<style scoped>

::v-deep .tiptap-vuetify-editor__content {
  height: calc( 100vh - 125px );
  scrollbar-width: auto;
}
</style>
