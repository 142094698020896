<template>
  <span>
    <v-overlay :value="isLoading" z-index="500">
      <div class="text-center pa-8 rounded-lg" style="width: 200px;">
         <v-progress-circular indeterminate color="white"></v-progress-circular>
      </div>
    </v-overlay>
    <v-dialog
      v-model="isOpen"
      persistent
      max-width="400"
    >
    <v-card>
      <v-card-title class="text-h5">
        <span>
          You sure you want to <span class="text-decoration-underline"> {{ course.isPublic ? 'Hide' : 'Show' }}</span>
        </span>
        <span>
          <v-avatar size="42">
            <v-img contain :src="course.organization.logoUrl"></v-img>
          </v-avatar>
          <span class="ml-2">
            {{ course.name }} ?
          </span>
        </span>
      </v-card-title>
      <v-card-text>{{ course.isPublic ? 'Hiding this course will prevent customers from knowing this course exists!' : 'Showing this course will allow customers to see the course online!' }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="doClose"
        >
          Cancel
        </v-btn>
        <v-btn
          :color="course.isPublic ? 'red' : 'success'"
          text
          @click="doToggleActivation"
        >
          {{ course.isPublic ? 'Hide' : 'Show' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </span>
</template>

<script>
export default {
  name: 'togglePublic',
  data: () => ({
    isLoading: false,
    isOpen: false,
    course: {
      id: 0,
      name: String(),
      organization: String(),
      isPublic: null
    }
  }),
  methods: {
    doOpen (id) {
      this.course.id = id
      this.isLoading = true

      this.$services.course.doRead(id).then(course => {
        this.course.name = course.name
        this.course.organization = course.organization
        this.course.isPublic = course.isPublic

        this.isOpen = true
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doToggleActivation () {
      this.isOpen = false

      this.isLoading = true

      if (this.course.isPublic) {
        this.$services.course.doHidePublicly(this.course.id).then().catch(error => {
          this.$root.$emit('showError', error.message)
        }).finally(_ => {
          this.isLoading = false
          this.doClose()
        })
      } else {
        this.$services.course.doShowPublicly(this.course.id).then().catch(error => {
          this.$root.$emit('showError', error.message)
        }).finally(_ => {
          this.isLoading = false
          this.doClose()
        })
      }
    },
    doClose () {
      this.$emit('doLoad')
      this.isOpen = false
    }
  }
}
</script>

<style scoped>

</style>
