<template>
  <span>
    <v-container>
      <toggle-activation ref="toggleActivation" @doLoad="doLoad"></toggle-activation>
      <toggle-public ref="togglePublic" @doLoad="doLoad"></toggle-public>
      <v-row class="mb-4">
        <v-col align="right">
          <v-btn class="primary" @click="doOpenAddCourse" :disabled="userRole === 'employee'">
            Add course
          </v-btn>
        </v-col>
      </v-row>
      <v-card>
        <v-card-title>
          <div>
            <v-text-field v-model="search" label="Search" outlined style="width: 300px;"></v-text-field>
          </div>
        </v-card-title>
        <v-data-table :headers="headers" :items="courses" sort-by="sort" :sort-desc="true" :search="search" class="elevation-1" item-key="id" :loading="isLoading" loading-text="Loading courses" @click:row="doOpenRow($event)" @contextmenu:row="doShowRow($event)">
        <template v-slot:top></template>
        <template v-slot:item.organization="{ item }">
          <div class="py-4">
            <v-avatar size="42">
              <v-img contain :src="item.organization.logoUrl"></v-img>
            </v-avatar>
            <span class="ml-2">
              {{ item.organization.name }}
            </span>
          </div>
        </template>
        <template v-slot:item.name="{ item }">
          <div class="py-4 font-weight-bold text-h6">
            {{ item.name }}
          </div>
        </template>
        <template v-slot:item.subtitle="{ item }">
          <div class="py-4 text-truncate" style="max-width: 200px">
            {{ item.subtitle }}
          </div>
        </template>
        <template v-slot:item.cents="{ item }">
          <v-chip class="font-weight-bold">
            ${{ item.cents / 100 }}
          </v-chip>
        </template>
          <template v-slot:item.isPublic="{ item }">
          <v-switch
            class="mt-0"
            color="success"
            v-model="item.isPublic"
            hide-details
            @click="doTogglePublic($event, item.id)"
            :disabled="userRole === 'employee'"
          ></v-switch>
        </template>
        <template v-slot:item.isActive="{ item }">
          <v-switch
            class="mt-0"
            color="success"
            v-model="item.isActive"
            hide-details
            @click="doToggleActivation($event, item.id)"
            :disabled="userRole === 'employee'"
          ></v-switch>
        </template>
        <template v-slot:item.sort="{ item }">
          <div class="text-center">
            <v-btn @click="doUpdateSort($event, item.id, (item.sort + 1))"><font-awesome-icon :icon="['fas', 'arrow-up']"/></v-btn>
            <v-chip class="font-weight-bold mx-1">
              {{ item.sort }}
            </v-chip>
            <v-btn @click="doUpdateSort($event, item.id, (item.sort - 1))" :disabled="item.sort === 0"><font-awesome-icon :icon="['fas', 'arrow-down']"/></v-btn>
          </div>
        </template>
      </v-data-table>
      </v-card>
    </v-container>
    <view-course ref="viewCourse" @doLoad="doLoad"></view-course>
    <add-course ref="addCourse" @doLoad="doLoad"></add-course>
  </span>
</template>

<script>
import ViewCourse from '@/views/admin/courses/viewCourse'
import AddCourse from '@/views/admin/courses/addCourse'
import ToggleActivation from '@/views/admin/courses/toggleActivation'
import TogglePublic from '@/views/admin/courses/togglePublic.vue'
export default {
  name: 'Classes',
  components: { TogglePublic, ToggleActivation, AddCourse, ViewCourse },
  data: () => ({
    isLoading: false,
    search: String(),
    headers: [
      {
        text: 'Organization',
        value: 'organization',
        sortable: false
      },
      {
        text: 'Name',
        value: 'name',
        sortable: false
      },
      {
        text: 'Subtitle',
        value: 'subtitle',
        sortable: false
      },
      {
        text: 'Cost',
        value: 'cents',
        sortable: false
      },
      {
        text: 'Public',
        value: 'isPublic',
        sortable: false
      },
      {
        text: 'Active',
        value: 'isActive',
        sortable: false
      },
      {
        text: 'Order',
        align: 'start',
        sortable: false,
        value: 'sort'
      }
    ],
    courses: [],
    userRole: null
  }),
  methods: {
    doOpenRow (row) {
      this.$refs.viewCourse.doOpen(row.id)
    },
    doShowRow (row) {
      console.log(row.id)
    },
    doOpenAddCourse () {
      this.$refs.addCourse.doOpen()
    },
    doLoad () {
      this.isLoading = true
      this.userRole = this.$store.getters.doGetRole

      this.$services.course.doGetAll().then(courses => {
        this.courses = courses
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doToggleActivation (event, id) {
      event.stopPropagation()

      this.$refs.toggleActivation.doOpen(id)
    },
    doTogglePublic (event, id) {
      event.stopPropagation()

      this.$refs.togglePublic.doOpen(id)
    },
    doUpdateSort (event, id, sort) {
      event.stopPropagation()

      this.isLoading = true

      this.$services.course.doUpdateSort(id, sort).then().catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.doLoad()
      })
    }
  },
  mounted () {
    this.doLoad()
  }
}
</script>

<style scoped>

</style>
